<template>
  <div class="ScaleClass bg-white">
    <Row class="px-1 pb-1" :style="rowStyle">
      <i-col span="8" style="max-width: 54rem">
        <Card style="margin-bottom:12px" v-for="item in tData" :key="item.packageId">
          <p slot="title">{{item.packageName}}</p>
          <Button slot="extra" type="primary" size="small" style="margin-right: 0.25rem">
            修改
          </Button>
          <Button slot="extra" type="warning" size="small" style="margin-right: 0.25rem">
            删除
          </Button>
          <div style="display:flex">
            <div style="width:30%;float:left;">
              <img :src="item.image" style="width:120px" />
            </div>
            <div style="width:60%">
              <p>{{item.describe}}</p>
            </div>
          </div>
        </Card>
        <!-- <Table
          border
          stripe
          highlight-row
          ref="scaleClassTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          :height="theight"
          @on-current-change="selectRow"
        ></Table>-->
      </i-col>
      <i-col span="2" class="buttonCol">
        <Button class="mb-1" type="default" icon="md-add"></Button>
        <!-- <Button class="mb-1" type="default" icon="md-create"></Button>
        <Button class="mb-1" type="default" icon="md-arrow-up"></Button>
        <Button class="mb-1" type="default" icon="md-arrow-down"></Button>
        <Button type="default" icon="md-trash"></Button>-->
      </i-col>
      <i-col span="10" style="height: 100%; text-align: justify;">
        <Transfer
          filterable
          style="height: 100%;"
          :list-style="listStyle"
          :data="transData"
          :target-keys="targetKeys"
          :titles="listTitle"
          @on-change="handleChange"
        ></Transfer>
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "ScalePackage",
  data() {
    return {
      tData: [],
      thisRow: [],
      theight: window.innerHeight - 185,
      transData: [],
      targetKeys: [],
      listTitle: ["未选量表", "已选量表"],
      rowStyle: {
        height: (window.innerHeight - 177) / 16 + "rem",
      },
      tColumns: [
        {
          title: "编号",
          key: "packageId",
          width: 50,
        },
        {
          title: "名称",
          key: "packageName",
          width: 150,
        },
        {
          title: "原价",
          key: "originalPrice",
          width: 60,
        },
        {
          title: "组合价",
          key: "price",
          width: 60,
        },
        {
          title: "描述",
          key: "describe",
          tooltip: true,
        },
      ],
      listStyle: {
        "max-width": "25rem",
        width: "44%",
        height: "100%",
      },
      alreadyScaleList: [],
      searching: true,
      packageId: -1,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => {
        this.rowStyle.height = (window.innerHeight - 177) / 16 + "rem";
        this.theight = window.innerHeight - 185;
      },
      false
    );
    // 获取量表列表
    this.$get("Scale/GetScalePackageList", {}).then((res) => {
      this.tData = res.data.responseList;
      this.searching = false;
      let _row = this.tData[0];
      this.selectRow(_row);
      this.setTargetKeys();
    });
  },
  methods: {
    handleChange(newTargetKeys, direction, moveKeys) {
      if (direction == "left") {
        this.deleteScale(moveKeys);
      } else {
        this.setScale(moveKeys);
      }
    },
    deleteScale(moveKeys) {
      let val = "";
      moveKeys.forEach((element) => {
        val += element + ",";
      });
      this.$post("Scale/DeletePackageScale", {
        packageId: this.packageId,
        val: val,
      }).then((res) => {
        if (res.data.code) {
          this.$Message.success({
            content: "删除成功",
            duration: 3,
          });
          this.selectRow({ packageId: this.packageId });
        } else {
          this.$Message.error({
            content: "删除失败，请重试",
            duration: 3,
          });
        }
      });
    },
    setScale(moveKeys) {
      let val = "";
      moveKeys.forEach((element) => {
        val += element + ",";
      });
      this.$post("Scale/SetPackageScale", {
        packageId: this.packageId,
        val: val,
      }).then((res) => {
        if (res.data.code) {
          this.$Message.success({
            content: "设置成功",
            duration: 3,
          });
          this.selectRow({ packageId: this.packageId });
        } else {
          this.$Message.error({
            content: "设置失败，请重试",
            duration: 3,
          });
        }
      });
    },
    selectRow(currentRow) {
      this.packageId = currentRow.packageId;
      this.targetKeys = [];
      // 获取量表列表
      this.$get("Scale/GetPackageScaleList", {
        packageId: currentRow.packageId,
      }).then((res) => {
        // 选择数据行事件
        this.transData = res.data.response.scaleList;
        this.alreadyScaleList = res.data.response.alreadyScaleList;
        this.setTargetKeys();
      });
    },
    setTargetKeys() {
      for (let i = 0; i < this.transData.length; i++) {
        for (let j = 0; j < this.alreadyScaleList.length; j++) {
          if (this.transData[i].key == this.alreadyScaleList[j]) {
            this.targetKeys.push(this.transData[i].key);
          }
        }
      }
    },
    setClassOrMeal() {},
  },
};
</script>

<style lang="less">
.ivu-transfer-list-header,
.ivu-transfer-list-body {
  border-radius: 0;
}
.ivu-card-head {
  text-align: left;
}
.ivu-card-body {
  text-align: left;
}
.buttonCol {
  max-width: 4rem;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
}
</style>
